import { axiosBackEndInstance } from '../Axios';
import { END_POINT_GENERIC_MATERIAL } from '../Axios/ENDPOINTS';
import { notification } from 'antd';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { MaterialProps, ResponseGetMaterials, useGetMaterials } from './useGetMaterials';

export interface MaterialsValues{
  code: string,
  gdus_to_flower: number,
  brand: {
    label: string,
    value: string,
    key: number
  },
  material_type:  {
    label: string,
    value: string,
    key: number
  } ,
  female_material:  {
    label: string,
    value: string,
    key: number
  } | undefined,
  male_material:  {
    label: string,
    value: string,
    key: number
  } | undefined,
  suggested_sow_relation:  {
    label: string,
    value: string,
    key: number
  } | undefined,
  female_sow_gdus: number,
  male_1_sow_gdus: number,
  male_2_sow_gdus: number,
  male_3_sow_gdus: number   
}  

export interface MaterialsValuesNew{
  code: string,
  brand: number,
  material_type:  number ,
  female_material: number | undefined, 
  male_material: number| undefined,
  suggested_sow_relation: number | undefined,
  female_sow_gdus: number | undefined,
  male_1_sow_gdus: number | undefined,
  male_2_sow_gdus: number | undefined,
  green_tons: number | undefined,
  materials?: number,
} 

export const useMaterials= () => {

  const {errorHandling} = useErrorHandling()
  const {mutate} = useGetMaterials()
    
  const getFilteredMaterial = async (
    callback: Function,
    material_code: string,
    gdus_to_flowering: number,
    brand_id: number,
    material_type: number,
    pageValue?: string | undefined,        
    ): Promise<MaterialProps[] | undefined> => {
    
    var params = new URLSearchParams();

    pageValue && params.append("page", pageValue)
    material_code && params.append("material_code", material_code)
    material_type && params.append("material_type", material_type.toString())
    gdus_to_flowering && params.append("gdus_to_flowering", gdus_to_flowering.toString())  
    brand_id && params.append("brand_id", brand_id.toString())  

    try {
      const {
        data,
      }: { data: any } = await axiosBackEndInstance.get(
        END_POINT_GENERIC_MATERIAL,
        { 
          params
        }
      );
      callback()
      return data;
    } catch (newError) {
      console.log(newError);
      callback()
    }
  };

  const createMaterial = async (
    callback: Function,
    values: MaterialsValuesNew,
    cancelLoading: Function
  ): Promise<any> => {      
    try {
      const  data = await axiosBackEndInstance.post(
        END_POINT_GENERIC_MATERIAL, values
      )
      const status =  errorHandling(data).request.status
      if(status === 201){
        mutate()
        callback()
        notification.success({
          message: 'Éxito', 
          description:'Material creado con éxito', 
          placement: 'topRight'
        })
      }
    } catch (newError: any) {
      if(newError?.response?.data?.non_field_errors[0].includes('460')){
        notification.info({
          message: 'info', 
          description:'Ya existe un material con ese códgo ', 
          placement: 'topRight'
        })
        cancelLoading(false)
      }else{
        notification.error({
          message: 'error', 
          description:'Hubo un error, comuníquese con el administrador', 
          placement: 'topRight'
        })
        console.log(newError);
        callback()
      }
    }
  };

  const editMaterials = async (
    callback: Function,
    id: number,
    values: MaterialsValuesNew                           
  ): Promise<void> => {            
    try {
      const data  = await axiosBackEndInstance.patch(
        `${END_POINT_GENERIC_MATERIAL}/${id}`,values
      );
      callback()   
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Material editado con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {      
      callback()
      let status = errorHandling(newError).request.status
      console.log(newError);
      console.log(status)
    }
  };

  const deleteMaterial = async (
    callback: Function,
    id: number                            
  ): Promise<void> => {        
    try {
      const data  = await axiosBackEndInstance.delete(
        `${END_POINT_GENERIC_MATERIAL}/${id}`, 
      );
      callback()   
      if(data.status === 200){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Material eliminado con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      callback()
      let status = errorHandling(newError).request.status
      console.log(newError);
      console.log(status)
    }
  };

  return {
    getFilteredMaterial,
    createMaterial,
    deleteMaterial,
    editMaterials
  };
};
