const LOGIN = '/login';
const URL = '/url';
const END_POINT_ACCOUNT_TOKEN = '/account/token/';
const END_POINT_ACCOUNT_PROFILE ='account/profile/';
const END_POINT_GENERIC_ZONE = '/generic/zone';
const END_POINT_GENERIC_ZONE_FOR_DISTRIBUTION = '/generic/zone_for_distribution';
const END_POINT_GENERIC_PROVINCE = '/generic/province';
const END_POINT_GENERIC_LOT = 'generic/lot';
const END_POINT_GENERIC_FARM_OWNER = 'generic/farmOwner';
const END_POINT_GENERIC_DETASSELED_EQUIPMENT = 'generic/detasseled_equipment';
const END_POINT_GENERIC_MATERIAL_FLOWERING_BY_MONTH = 'generic/material_flowering_by_month';
const END_POINT_GENERIC_FARM = 'generic/farm';
const END_POINT_GENERIC_CITY ='generic/city';
const END_POINT_GENERIC_CAMPAIGN ='generic/campaign';
const END_POINT_GENERIC_MATERIAL_TYPE ='generic/materialType'; 
const END_POINT_GENERIC_MATERIAL ='generic/material'; 
const END_POINT_GENERIC_HYBRID ='generic/hybrid'; 
const END_POINT_GENERIC_PARENTAL ='generic/parental'; 
const END_POINT_GENERIC_BRAND ='generic/brand'; 
const END_POINT_GENERIC_SOW_RELATION ='generic/sow_relation'; 
const END_POINT_GENERIC_GDU_BY_ZONE ='generic/gdu_by_zone'; 
const END_POINT_GENERIC_YIELD_BY_ZONE ='generic/yield_by_zone';
const END_POINT_GENERIC_YIELD_BY_ZONE_FOR_DISTRIBUTION ='generic/yield_by_zone_for_distribution'
const END_POINT_GENERIC_HISTORICAL_GDUs_BY_ZONE = '/generic/historical_gdu_by_zone';
const END_POINT_LOTS_BY_PRODUCTION = '/lots_by_production/lot_by_production';
const END_POINT_LOTS_BY_PRODUCTION_BAG_ORDER = '/lots_by_production/bag_order';
const END_POINT_LOTS_BY_PRODUCTION_SOW_PLANIFICATION_VIEW= '/lots_by_production/sow_planification_view';
const END_POINT_LOTS_BY_PRODUCTION_BAG_DISTRIBUTION = '/lots_by_production/bag_distribution';
const END_POINT_LOTS_BY_PRODUCTION_DAY_OF_MANUAL_DETASSELING_BY_LOT = '/lots_by_production/days_of_manual_detasseling_by_lot';
const END_POINT_LOTS_BY_PRODUCTION_PROGRESS = '/lots_by_production/lots_by_production_progress';
const END_POINT_LOTS_BY_PRODUCTION_PROGRESS_EXPORT = '/lots_by_production/lots_by_production_progress_export';
const END_POINT_LOTS_BY_PRODUCTION_EXPORT_DATA = '/lots_by_production/lot_by_production_export';
const END_POINT_LOTS_BY_PRODUCTION_EXPORT_LOTS = '/lots_by_production/lot_by_production_export_lots';
const END_POINT_LOTS_BY_PRODUCTION_SOW_PLANIFICATION_VIEW_EXPORT = '/lots_by_production/sow_planification_view_export';


export {
    LOGIN,
    URL,
    END_POINT_ACCOUNT_TOKEN,
    END_POINT_GENERIC_ZONE,
    END_POINT_GENERIC_PROVINCE,
    END_POINT_GENERIC_LOT,
    END_POINT_GENERIC_FARM_OWNER,
    END_POINT_GENERIC_DETASSELED_EQUIPMENT,
    END_POINT_GENERIC_FARM,
    END_POINT_GENERIC_CITY,
    END_POINT_GENERIC_CAMPAIGN,
    END_POINT_GENERIC_MATERIAL_TYPE,
    END_POINT_ACCOUNT_PROFILE,
    END_POINT_GENERIC_MATERIAL,
    END_POINT_GENERIC_BRAND,
    END_POINT_GENERIC_SOW_RELATION,
    END_POINT_GENERIC_GDU_BY_ZONE,
    END_POINT_LOTS_BY_PRODUCTION_BAG_ORDER,
    END_POINT_LOTS_BY_PRODUCTION_SOW_PLANIFICATION_VIEW,
    END_POINT_GENERIC_HISTORICAL_GDUs_BY_ZONE,
    END_POINT_LOTS_BY_PRODUCTION,
    END_POINT_GENERIC_HYBRID,
    END_POINT_GENERIC_MATERIAL_FLOWERING_BY_MONTH,
    END_POINT_GENERIC_PARENTAL,
    END_POINT_GENERIC_YIELD_BY_ZONE,
    END_POINT_LOTS_BY_PRODUCTION_BAG_DISTRIBUTION,
    END_POINT_LOTS_BY_PRODUCTION_DAY_OF_MANUAL_DETASSELING_BY_LOT,
    END_POINT_LOTS_BY_PRODUCTION_PROGRESS,
    END_POINT_LOTS_BY_PRODUCTION_PROGRESS_EXPORT,
    END_POINT_GENERIC_YIELD_BY_ZONE_FOR_DISTRIBUTION,
    END_POINT_GENERIC_ZONE_FOR_DISTRIBUTION,
    END_POINT_LOTS_BY_PRODUCTION_EXPORT_DATA,
    END_POINT_LOTS_BY_PRODUCTION_SOW_PLANIFICATION_VIEW_EXPORT,
    END_POINT_LOTS_BY_PRODUCTION_EXPORT_LOTS
}