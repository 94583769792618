import React, { useContext, useEffect, useState } from "react";
import {  FormPopupProps } from "./types";
import { Button, Col, DatePicker,  Form,  Input, Modal, Row, message  } from "antd";
import { useForm } from "antd/es/form/Form";
import {PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from "dayjs";
import { useDaysOfManualDetasselingByLot } from "../../../Services/useDaysOfManualDetasselingByLot";
import { PlanificationContext } from "../../../Contexts/UserContext/PlanificationContext";
import { FormItemProps } from "../ModifyHumanResources/types";


export const FormPopup: React.FC<FormPopupProps> = ({
    rowSelect,
    mutateTable,
    setShowForm,
    showForm,
    messageApi
}) =>{

    const {editDaysOfManualDatasseling} = useDaysOfManualDetasselingByLot()
    const {zoneAndCampaignSelected, resources  } = useContext(PlanificationContext)
    console.log('FormPopup')

    const [ form_human_resources ] = useForm()
    const [ amountRows, setAmountRows ] = useState<FormItemProps[]>([])
    const [loadingBtn, setLoadingBtn] = useState(false)

    useEffect(()=>{
        console.log('FormPopup 2')
        form_human_resources.resetFields() 
        const newData = resources[rowSelect.id]
        setAmountRows(newData)      
    },[])
     
    const callback = () =>{
        console.log('FormPopup 3') 
        setShowForm(false)  
        form_human_resources.resetFields() 
        setAmountRows([])
        setLoadingBtn(false)
    }

    const handleSave = async (calculateBased: boolean) =>{
        setLoadingBtn(true)
        await editDaysOfManualDatasseling(callback, rowSelect.id, calculateBased ? [] : amountRows)          
        mutateTable(zoneAndCampaignSelected) 
        messageApi.open({
            type: 'loading',
            content: 'Actualizando...',
            duration: 2
        });         
    }

    const add = () =>{       
        const newRow = {
            date: undefined,
            quantity: undefined,
        };
        setAmountRows([...amountRows, newRow]);
    }

    const remove = (index: number) => {
        const updatedRows = amountRows.filter((_, i) => i !== index);
        setAmountRows(updatedRows);

        // Reset form fields and reassign names based on new indices
        form_human_resources.resetFields();
        updatedRows.forEach((item, newIndex) => {
            form_human_resources.setFieldsValue({
                [`date_${newIndex}`]: item.date ? dayjs(item.date, 'YYYY-MM-DD') : null,
                [`quantity_${newIndex}`]: item.quantity,
            });
        });
    };

    const handleChangeDatePicker = (e: dayjs.Dayjs, item: FormItemProps, index: number)=>{
        const updatedAmountRows = [...amountRows];
        updatedAmountRows[index] = {
            ...updatedAmountRows[index],
            date: e ? e.format('YYYY-MM-DD') : ''
        };
        if(!form_human_resources.getFieldValue( [`date_${index}`])){
            form_human_resources.setFieldsValue({
                [`date_${index}`]:null})
        }
        setAmountRows(updatedAmountRows);
    }

    const handleChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>, item: FormItemProps, index: number) => {
        const updatedAmountRows = [...amountRows];
        updatedAmountRows[index] = {
            ...updatedAmountRows[index],
            quantity: Number(e.target.value)
        };
        setAmountRows(updatedAmountRows);
    };

    const handleCancel = ()=>{
        setShowForm(false)
    }
    return(
        <>
            <Modal
                title={ 'Asignar recursos a: ' + rowSelect.code + ' - ' + rowSelect.farm_code}
                open={showForm}
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}              
                onOk={()=>handleSave(false)}
                footer={null} 
                width={400}
            >
                    <Form
                        style={{marginTop: '20px'}}
                        form={form_human_resources}
                        id='form_human_resources'
                        onFinish={()=>handleSave(false)}
                        layout="vertical"                
                    >
                    {
                        amountRows.map((item, index)=>
                            <Row key={index}>
                                <Col span={11}>
                                    <Form.Item
                                        name={`date_${index}`}
                                        initialValue={item.date ? dayjs(item.date, 'YYYY-MM-DD') : null}
                                        rules={[
                                            {
                                                required: true,
                                                message:'Este campo es obligatorio'
                                            }
                                        ]}
                                    >              
                                        <DatePicker
                                            onChange={(e)=>handleChangeDatePicker(e , item, index)}
                                            placeholder="Fecha"    
                                                                    
                                        />                                                              
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        name={`quantity_${index}`}
                                        initialValue={item.quantity}
                                        rules={[
                                            {
                                                required: true,
                                                message:'Este campo es obligatorio'
                                            }
                                        ]}
                                    >
                                        <Input                                
                                            placeholder="Cantidad"
                                            type="number"
                                            onChange={(e)=>handleChangeQuantity(e , item, index)}
                                            
                                        /> 
                                    </Form.Item>  
                                </Col>  
                                <Col span={2}>
                                    <DeleteOutlined 
                                        onClick={()=>remove(index)}
                                        className= 'icons'
                                        style={{ marginLeft: '10px', cursor: 'pointer'}}/>
                                </Col>                                                                                  
                            </Row>                
                        )
                    }    
                    <Form.Item>
                        <Button
                            type="dashed"
                            onClick={() => add()}
                            style={{ width: '100%' }}
                            icon={<PlusOutlined />}
                        >
                            Agregar fecha
                        </Button>            
                    </Form.Item>    
                    <Form.Item>
                        <Button
                            onClick={()=>handleSave(true)}
                            danger
                            style={{ width: '100%'}}                
                        >
                            Calculo automático
                        </Button>   
                    </Form.Item>                 
                    <Form.Item 
                        style={{                
                            width: '100%',
                        }}
                    >
                        <div style={{
                            width: '100%',
                            display:'flex',
                            justifyContent: 'space-between'
                        }}>
                            <Button type="primary" htmlType="submit">
                                Guardar
                            </Button>
                            <Button  onClick={()=>{
                                setShowForm(false)
                            }} 
                            >
                                Cancelar
                            </Button>
                        </div>                
                    </Form.Item>            
                    </Form>
                
            </Modal> 
        </>
        
    )
}