import React, { useContext, useEffect, useState } from 'react';
import './styles.scss';
import logo from '../../assets/logo/Corteva_logo-white.png'
import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { UserContext } from '../../Contexts/UserContext/UsersContext';
import { useNavigate } from 'react-router';

const Navbar: React.FC = () => {

    const { setAccesToken, userData } = useContext(UserContext);
    const navigate = useNavigate();

    const [navbarHeight, setNavbarHeight] = useState<number>(window.innerHeight*0.076) 

    useEffect(() => {    
  
      const handleResize = () => {
        setNavbarHeight(window.innerHeight*0.076)
      };
  
      handleResize();
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      
    }, []);

    const items: MenuProps['items'] = [
      {
        key: '1',
        label: 'Perfil',
        onClick: ()=>{
          setAccesToken(undefined)
          navigate('/')
        }
      },
      {
        key: '2',
        label: 'Cerrar sesión',
        onClick: ()=>{
          setAccesToken(undefined)
          navigate('/')
        }
      },
    ];
  
    return( 
        <div 
          className='navbar_container'
          style={{
            height: `${navbarHeight}px`
          }}
        >
          <img src={logo}/>            
          <Dropdown
              trigger={['click']}
              menu={{
              items,
              }}               
          >
            <div className='navbar_profile'>
              {
                  userData?.first_name && userData?.last_name 
                  ?  <h4>{userData?.first_name} {userData?.last_name}</h4> 
                  :  <h4>{userData?.username}</h4> 
              }                                  
              <DownOutlined style={{
                  color:'white',
                  marginLeft: '15px',
                  marginTop:'3px',
                  fontSize: '12px',
                  transition: 'all 0.2s ease-in-out',
              }} />  
            </div>                              
          </Dropdown>   
        </div>                
    )};

export default Navbar;

